/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Keycloak from 'keycloak-js';
import {
  Dashboard,
  Group,
  // VpnKey,
  DeveloperMode,
  PrecisionManufacturing,
  ContentPasteSearch,
  BuildCircle,
  Update,
  Search,
  BatteryUnknown,
  Business,
  Handshake,
  SimCard as SimCardIcon,
} from '@mui/icons-material';
import io from 'socket.io-client';
import { Navigation } from './Navigation';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import API from './api/apiConfig';
import { UserPermissionsArr } from './UserPermissionsArr';
import { useUserContext } from './context/user-context';
import Home from './Home';
import SessionExpired from './Components/SessionExpired';
import { getDeviceType } from './api/getDeviceType';
import cogoToast from 'cogo-toast';

// routes - lazy loaded.
const Devices = lazy(() => import('./routes/devices/Devices'));
const BulkFota = lazy(() => import('./routes/devices/Others/BulkFota'));
const BulkConfigUpdateRouter = lazy(() => import('./routes/devices/Others/BulkConfigUpdateRouter'));
const BatteryLifeEstimator = lazy(() => import('./routes/devices/Others/BatteryLifeEstimator'));
const BulkTest = lazy(() => import('./routes/devices/Others/BulkTest'));
const BulkSimConfigRouter = lazy(() => import('./routes/devices/Others/BulkSimConfig'));
const FirmwareManagement = lazy(() => import('./routes/FirmwareManagement/FirmwareManagement'));
const CRM = lazy(() => import('./routes/CRM/CRM'));
// const ApiKeys = lazy(() => import('./routes/apiKeys/ApiKeys'));
const OldApplications = lazy(() => import('./routes/oldApplications/OldApplications'));
const UserManagement = lazy(() => import('./routes/UserManagement/UserManagement'));
const Manufacturing = lazy(() => import('./routes/Manufacturing/Manufacturing'));
const PostProdInsp = lazy(() => import('./routes/OperationalCheck/PostProductionInspection/PostProductionInsp'));
// const CalibrationCertManagement = lazy(() => import("./routes/CalibrationCertManagement/CalibrationCertManagement"));

const APIURL = process.env.REACT_APP_APIURL;
const SOCKETURL = process.env.REACT_APP_SOCKETURL;

API.defaults.withCredentials = true;

function App() {
  const [socket, setSocket] = useState(null);
  const [socketConnected, setSocketConnected] = useState(false);
  const [udpLog, setUdpLog] = useState(null);
  const [joinedRoom, setJoinedRoom] = useState(null);
  // const [authenticated, setAuthenticated] = useState(false);
  const [keycloak, setKeycloak] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setAccountUser, setPermissions, setDeviceTypes } = useUserContext();
  const theme = createTheme({
    palette: {
      primary: {
        main: '#008081',
      },
      secondary: {
        main: '#008081',
      },
    },
  });

  const userPermissions = () => {
    API.get(`${APIURL}/roles/attributes`)
      .then((response) => {
        const permissionsData = response.data.data.filter(
          (item) => {
            if (item.role === 'DEFAULT' || item.role === 'uma_protection') {
              return null;
            }
            return item;
          },
        );
        const permissionsArray = Object.keys(UserPermissionsArr);
        permissionsArray.forEach((userArr) => {
          const actionsArr = Object.keys(UserPermissionsArr[userArr]);
          actionsArr.forEach((action) => {
            const isTrue = !!permissionsData.find((rolePermissions) => {
              if (
                rolePermissions.attributes
                  && rolePermissions.attributes.permissions
                  && rolePermissions.attributes.permissions.length
                  && rolePermissions.attributes.permissions[0][userArr]
              ) {
                return !!rolePermissions.attributes.permissions[0][userArr][
                  action
                ];
              }
              return false;
            });
            UserPermissionsArr[userArr][action] = isTrue;
          });
        });
        setPermissions(UserPermissionsArr);
      })
      .catch((error) => console.log('error setting permissions', error.message || error));
  };

  const fetchDeviceTypes = async () => {
    try {
      const deviceTypeData = await getDeviceType();
      setDeviceTypes(deviceTypeData);
    } catch (err) {
      console.error('error is', err);
    }
  };

  const initiateSockets = (accessToken) => {
    const sckt = io(SOCKETURL, {
      auth: { token: accessToken },
      secure: true,
    });
    window.TITAN = window.TITAN || {};
    window.TITAN.socket = sckt;
    sckt.on('connect', () => {
      if (!socket) setSocket(sckt);
      setSocketConnected(true);
      sckt.on('log', (data) => {
        if (data?.msg.startsWith('you are connected to')) {
          cogoToast.info(`Socket connected to ${data.msg.split(': ')[1]}`, { position: 'top-right' });
        } else console.log('socket log::', data);
      });
      sckt.on('UDPlog', (data) => {
        setUdpLog(data.msg);
      });
    });

    sckt.on('reconnect', (attempt) => {
      console.log('reconnected to socket in attempt', attempt);
      if (joinedRoom && socket) {
        console.log('rejoing socket room', joinedRoom);
        sckt.emit('joinRoom', { name: joinedRoom });
      }
    });

    sckt.on('disconnect', () => {
      console.log('socket disconnected !');
      sckt.off('log');
      sckt.off('UDPlog');
      setSocketConnected(false);
    });

    sckt.on('error', (e) => {
      console.error('error connecting sockets', e);
    });
  };

  const verify = (onLoad) => {
    const KC = Keycloak(`/${process.env.REACT_APP_KEYCLOAK_FILENAME || 'keycloakdev.json'}`);
    // if (window.location.pathname !== '/') setLoading(true);
    KC.init({
      onLoad,
      // silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    })
      .then((auth) => {
        // setAuthenticated(auth);
        setKeycloak(KC);
        if (auth) {
          window.accessToken = KC.token;
          setAccountUser(KC.tokenParsed);
          API.defaults.headers.common.Authorization = `Bearer ${KC.token}`;
          initiateSockets(KC.token);
          userPermissions();
          fetchDeviceTypes();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        cogoToast.error(`Error contacting auth server: ${err?.message}`, { position: 'top-right' });
        console.error(`Error contacting auth server: ${err?.message}`);
        KC.updateToken(50).success((refreshed) => {
          if (refreshed) {
            console.log('refreshed', new Date());
          }
        });
      });
  };

  useEffect(() => { verify(window.top === window.self ? 'check-sso' : 'login-required'); }, []);

  const joinRoomFromSocket = (uid) => {
    if (socketConnected && socket !== null) {
      socket.emit('joinRoom', { name: String(uid) }, (msg) => {
        if (msg?.status === 'success') cogoToast.info(`Joined ws room ${uid}`, { position: 'top-right' });
      });
      setJoinedRoom(uid);
    }
  };

  const getOutOfThatRoom = (uid) => {
    if (socketConnected && socket !== null) {
      setUdpLog(null);
      setJoinedRoom(null);
      socket.emit('leaveRoom', { name: `${uid}` });
    }
  };

  const handleFOTA = (data) => {
    if (socketConnected && socket !== null) {
      console.log('In the room and fota request is being sent');
      socket.emit('sendToKelvin', data);
    }
  };

  const handleBulkFota = (deviceUidsArray, fileName) => {
    if (!deviceUidsArray && !fileName) {
      return "Please provide deviceUidsArray & fineName as params to the function. Make sure deviceUidsArray is of format {['uid1', 'uid2']} & fileName is of the format {'FOTAS3:/TEST/firmware-kelvin-latest.bin'}";
    }
    if (!fileName) {
      console.warn(
        'fileName is not provided, sending fota request with default fileName. i.e,"FOTAS3:/TEST/firmware-kelvin-latest.bin"',
      );
      fileName = 'FOTAS3:/TEST/firmware-kelvin-latest.bin';
    }
    deviceUidsArray.forEach((eachUid) => {
      window.TITAN.socket.emit('joinRoom', { name: eachUid }); // socket to room connected
      console.log('joining room', eachUid);
      setTimeout(() => {
        console.log('sending data to fota', eachUid);
        const fotaData = {
          imei: eachUid,
          msg: fileName,
        };
        window.TITAN.socket.emit('sendToKelvin', fotaData); // sending the fota file info
      }, 2000);
    });
  };

  window.TITAN = window.TITAN || {};
  window.TITAN.bulkFota = handleBulkFota;

  const routes = [
    {
      path: 'devices',
      name: 'Devices',
      id: 1,
      icon: <Dashboard />,
      component: (props) => (
        <Devices
          {...props}
          joinRoomFromSocket={joinRoomFromSocket}
          getOutOfThatRoom={getOutOfThatRoom}
          udpLog={udpLog}
          handleFOTA={handleFOTA}
        />
      ),
      more: [
        {
          path: 'devices',
          name: 'Browse',
          id: 1,
          icon: <Search fontSize="small" />,
          component: (props) => (
            <Devices
              {...props}
              joinRoomFromSocket={joinRoomFromSocket}
              getOutOfThatRoom={getOutOfThatRoom}
              udpLog={udpLog}
              handleFOTA={handleFOTA}
            />
          ),
        },
        {
          path: 'devices/bulkConfig',
          name: 'Bulk Config',
          id: 1,
          icon: <BuildCircle fontSize="small" />,
          component: (props) => (
            <BulkConfigUpdateRouter {...props} />
          ),
        },
        {
          path: 'devices/recurringFOTA',
          name: 'Recurring FOTA',
          id: 1,
          icon: <Update fontSize="small" />,
          component: (props) => <BulkFota {...props} />,
        },
        {
          path: 'devices/bulkTest',
          name: 'Bulk Test',
          id: 1,
          icon: <ContentPasteSearch fontSize="small" />,
          component: (props) => <BulkTest {...props} />,
        },
        {
          path: 'devices/batterylife',
          name: 'Battery Life Estimator',
          id: 1,
          icon: <BatteryUnknown fontSize="small" />,
          component: (props) => <BatteryLifeEstimator {...props} />,
        },
        // {
        //   path: 'devices/simconfig',
        //   name: 'Sim Config',
        //   id: 1,
        //   icon: <SimCardIcon fontSize="small" />,
        //   component: (props) => <BulkSimConfigRouter {...props} />,
        // },
      ],
    },
    {
      path: 'firmware',
      name: 'Firmware Management',
      id: 2,
      icon: <DeveloperMode />,
      component: (props) => <FirmwareManagement {...props} />,
    },
    {
      path: 'usermanagement',
      name: 'User Management',
      id: 3,
      icon: <Group />,
      component: (props) => <UserManagement {...props} />,
    },
    // {
    //   path: 'apikeys',
    //   name: 'API Keys',
    //   id: 4,
    //   icon: <VpnKeyIcon />,
    //   component: (props) => <ApiKeys {...props} />,
    // },
    {
      path: 'applications',
      name: 'Applications',
      id: 5,
      icon: <Business />,
      component: (props) => <OldApplications {...props} />,
    },
    {
      path: 'crm',
      name: 'CRM',
      id: 2,
      icon: <Handshake />,
      component: (props) => <CRM keycloak={keycloak} {...props} />,
    },
    {
      path: 'manufacturing',
      name: 'Manufacturing',
      id: 6,
      icon: <PrecisionManufacturing />,
      component: (props) => <Manufacturing {...props} />,
    },
    {
      path: 'operationalCheck',
      name: 'Operational Check',
      id: 7,
      icon: <img
        src="/img/OC.png"
        alt="company logo"
        style={{ maxHeight: '28px' }}
      />,
      component: (props) => <PostProdInsp {...props} />,
      more: [
        {
          path: 'operationalCheck/postProductionInspection',
          name: 'Post Production Inspection',
          id: 1,
          icon: <img
            src="/img/ppiIcon.png"
            alt="company logo"
            style={{ maxHeight: '28px' }}
          />,
          component: (props) => (
            <PostProdInsp />
          ),
        },
      ],
    },
  ];

  return (
    !loading && (
      <ThemeProvider theme={theme}>
        <Router>
          {keycloak?.authenticated
            ? <Navigation keycloak={keycloak} routes={routes} loading={loading} />
            : <Home login={verify} />}
          <SessionExpired
            verify={verify}
            keycloak={keycloak}
            setKeycloak={setKeycloak}
          />
        </Router>
      </ThemeProvider>
    )
  );
}

export default App;
