import API from './apiConfig';

const APIURL = process.env.REACT_APP_APIURL;

export const getDeviceType = (storeValue) => new Promise((resolve, reject) => {
  const url = storeValue
    ? `${APIURL}/v1/deviceType/${storeValue}`
    : `${APIURL}/v1/deviceType?details=basic`;
  API.get(url)
    .then((response) => {
      if (
        response.data
      ) {
        const { data } = response;
        resolve(data);
      } else {
        reject(new Error(response));
      }
    })
    .catch((err) => {
      reject(err);
    });
});
