/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";
import API from "../api/apiConfig";
import {
  Card,
  Typography,
  Grid,
  CardContent,
  Dialog,
  Button,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import moment from "moment-timezone";
// import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import ReactJSON from "react-json-view";
import ReactEcharts from "echarts-for-react";
import DateAndTimeRangePicker from "./DateAndTimeRangePicker";

const APIURL = process.env.REACT_APP_APIURL;
API.defaults.withCredentials = true;

// const testIMEIs = ['54005317', '54008857'];
const testIMEIs = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  "54005317",
  48,
  49,
  50,
  51,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  100,
  101,
  102,
  103,
  104,
  105,
  106,
  107,
  108,
  109,
  110,
  111,
  112,
  113,
  114,
  115,
  116,
  117,
  118,
  119,
  120,
  121,
  122,
  123,
  124,
  125,
  126,
  127,
  128,
  129,
  130,
  131,
  132,
  133,
  134,
  135,
  136,
  137,
  138,
  139,
  140,
  141,
  142,
  143,
  144,
  145,
  146,
  147,
  148,
  149,
  150,
  151,
  152,
  153,
  154,
  155,
  156,
  157,
  158,
  159,
  160,
  161,
  162,
  163,
  164,
  165,
  166,
  167,
  168,
  169,
  170,
  171,
  172,
  173,
  174,
  175,
  176,
  177,
  178,
  179,
  180,
  181,
  182,
  183,
  184,
  185,
  186,
  187,
  188,
  189,
  190,
  191,
  192,
  193,
  194,
  195,
  196,
  197,
  198,
  199,
  200,
  201,
  202,
  203,
  204,
  205,
  206,
  207,
  208,
  209,
  210,
  211,
  212,
  213,
  214,
  215,
  216,
  217,
  218,
  219,
  220,
  221,
  222,
  223,
  224,
  225,
  226,
  227,
  228,
  229,
  230,
  231,
  232,
  233,
  234,
  235,
  236,
  237,
  238,
  239,
  240,
  241,
  242,
  243,
  244,
  245,
  246,
  247,
  248,
  249,
  250,
  251,
  252,
  253,
  254,
  255,
  256,
  257,
  258,
  259,
  260,
  261,
  262,
  263,
  264,
  265,
  266,
  267,
  268,
  269,
  270,
  271,
  272,
  273,
  274,
  275,
  276,
  277,
  278,
  279,
  280,
  281,
  282,
  283,
  284,
  285,
  286,
  287,
  288,
  289,
  290,
  291,
  292,
  293,
  294,
  295,
  296,
  297,
  298,
  299,
  300,
  301,
  302,
  303,
  304,
  305,
  306,
  307,
  308,
  309,
  310,
  311,
  312,
  313,
  314,
  315,
  316,
  317,
  318,
  319,
  320,
  321,
  322,
  323,
  324,
  325,
  326,
  327,
  328,
  329,
  330,
  331,
  332,
  333,
  334,
  335,
  336,
  337,
  338,
  339,
  340,
  341,
  342,
  343,
  344,
  345,
  346,
  347,
  348,
  349,
  350,
  351,
  352,
  353,
  354,
  355,
  356,
  357,
  358,
  359,
  360,
  361,
  362,
  363,
  364,
  365,
  366,
  367,
  368,
  369,
  370,
  371,
  372,
  373,
  "54008857",
  375,
  376,
  377,
  378,
  379,
  380,
  381,
  382,
  383,
  384,
  385,
  386,
  387,
  388,
  389,
  390,
  391,
  392,
  393,
  394,
  395,
  396,
  397,
  398,
  399,
  400,
  401,
  402,
  403,
  404,
  405,
  406,
  407,
  408,
  409,
  410,
  411,
  412,
  413,
  414,
  415,
  416,
  417,
  418,
  419,
  420,
  421,
  422,
  423,
  424,
  425,
  426,
  427,
  428,
  429,
  430,
  431,
  432,
  433,
  434,
  435,
  436,
  437,
  438,
  439,
];
const prodIMEIs = [
  "54002140",
  "54001795",
  "53995948",
  "54002207",
  "54007446",
  "54005945",
  "54000862",
  "54002496",
  "53996300",
  "53999551",
  "53998975",
  "54002066",
  "53996052",
  "54003221",
  "54000888",
  "54001811",
  "54005234",
  "53997373",
  "53998686",
  "54006075",
  "54002041",
  "54010382",
  "54001068",
  "54003122",
  "54009756",
  "54001514",
  "53996128",
  "54001977",
  "53999866",
  "54005747",
  "54000821",
  "54003114",
  "54012297",
  "54003593",
  "54012867",
  "54003668",
  "53998850",
  "54001332",
  "53997456",
  "54001761",
  "54001464",
  "54003684",
  "54005606",
  "53999650",
  "54001746",
  "53999643",
  "54002991",
  "53996227",
  "54002769",
  "54002181",
  "54000383",
  "54007164",
  "54002405",
  "53993679",
  "54001605",
  "54003023",
  "54003700",
  "54007206",
  "54002959",
  "53998819",
  "53997464",
  "54002157",
  "54004062",
  "53996805",
  "54002413",
  "54011851",
  "54005879",
  "53996151",
  "54003239",
  "54001928",
  "53997399",
  "54003254",
  "54002918",
  "53994586",
  "54001720",
  "54001803",
  "54002462",
  "54010119",
  "54000946",
  "54004047",
  "54003346",
  "54009236",
  "53994651",
  "53999155",
  "54002330",
  "54011406",
  "54010853",
  "54003163",
  "54003130",
  "54004070",
  "53999056",
  "54005416",
  "54004773",
  "54003189",
  "54003957",
  "54003817",
  "54001688",
  "54011174",
  "54011232",
  "54006844",
  "54004104",
  "54005358",
  "54005960",
  "54002108",
  "54000367",
  "53996276",
  "53997621",
  "53999759",
  "53997407",
  "54000987",
  "54004138",
  "53997605",
  "54005770",
  "54001340",
  "53993612",
  "54001225",
  "54003262",
  "54005796",
  "54000623",
  "54005598",
  "53993455",
  "54002447",
  "54003270",
  "54004252",
  "54005853",
  "54012651",
  "54005226",
  "53996680",
  "54002009",
  "54001456",
  "54003031",
  "53994297",
  "54001506",
  "54009517",
  "53999627",
  "54003577",
  "53999692",
  "54002264",
  "54000912",
  "54003676",
  "54000870",
  "53997282",
  "53999700",
  "54000599",
  "54002249",
  "54004542",
  "53999007",
  "54003791",
  "54010929",
  "53995807",
  "54003551",
  "54003692",
  "54008147",
  "54001647",
  "54002942",
  "53996391",
  "53997019",
  "54002975",
  "54001886",
  "54002967",
  "53997654",
  "54003338",
  "53996656",
  "53996672",
  "53999064",
  "53999247",
  "53998983",
  "54011737",
  "53999510",
  "54008717",
  "54001852",
  "54003353",
  "54003734",
  "54002702",
  "53997241",
  "54011588",
  "54000854",
  "54002371",
  "54002173",
  "53995971",
  "54003460",
  "54008493",
  "53999361",
  "54001829",
  "54003585",
  "54009616",
  "53997365",
  "54001209",
  "54003387",
  "53998892",
  "54001563",
  "53996086",
  "54011315",
  "54001233",
  "53996037",
  "54010317",
  "53998595",
  "54001712",
  "54012446",
  "54001480",
  "54008873",
  "54010887",
  "54010572",
  "54001498",
  "54005820",
  "53997639",
  "53996326",
  "53999684",
  "54003965",
  "53998652",
  "54010689",
  "54011273",
  "54001621",
  "53995989",
  "54003635",
  "53999742",
  "54004120",
  "53999163",
  "54001753",
  "54012552",
  "54010580",
  "53996136",
  "54002538",
  "54003445",
  "53999049",
  "54002751",
  "54005044",
  "54008303",
  "53999254",
  "54010820",
  "54001787",
  "54004245",
  "54009368",
  "54010507",
  "54010903",
  "54006505",
  "54010374",
  "54010481",
  "54003718",
  "54010325",
  "54012305",
  "54006018",
  "53996292",
  "54002934",
  "53996177",
  "54008030",
  "53998934",
  "54003197",
  "53997571",
  "54003486",
  "53998272",
  "54010333",
  "54012503",
  "54007727",
  "54001241",
  "54002389",
  "54001530",
  "54001258",
  "53994685",
  "54001019",
  "54003601",
  "54001191",
  "54001027",
  "54001381",
  "54008162",
  "54001183",
  "54004757",
  "54004328",
  "54003247",
  "54008220",
  "54003007",
  "53999890",
  "54010218",
  "53996649",
  "54009483",
  "54003643",
  "54011794",
  "54003056",
  "53998967",
  "54002926",
  "54003981",
  "54002900",
  "54006588",
  "54011950",
  "53999924",
  "54003619",
  "53995567",
  "53999536",
  "54011083",
  "54000995",
  "53999379",
  "54010952",
  "54003155",
  "54000631",
  "54002215",
  "54010515",
  "54006232",
  "53999791",
  "54006158",
  "54005440",
  "54004666",
  "54011224",
  "53999585",
  "54001993",
  "53993539",
  "53997555",
  "54002876",
  "53999072",
  "53999437",
  "54010242",
  "54003437",
  "54010390",
  "53999098",
  "54003106",
  "54004005",
  "54001571",
  "54011075",
  "54005366",
  "54010606",
  "54001837",
  "53996250",
  "54003148",
  "54002025",
  "54000409",
  "53999718",
  "54001894",
  "54008170",
  "54001365",
  "53999825",
  "53996235",
  "53997712",
  "54002512",
  "54008097",
  "54006307",
  "53997647",
  "53999809",
  "54003627",
  "53996425",
  "54003999",
  "54003379",
  "54004088",
  "54002124",
  "54001845",
  "54010556",
  "53999593",
  "54000953",
  "53998843",
  "53999668",
  "54007248",
  "54009392",
  "54002884",
  "54004732",
  "54002983",
  "53998959",
  "54003205",
  "54001357",
  "53999114",
  "54010523",
  "54001282",
  "53999130",
  "54004377",
  "53999502",
  "53995757",
  "54003072",
  "54006331",
  "53999478",
  "53997449",
  "54001589",
  "54002470",
  "54010473",
  "54012180",
  "54000938",
  "54009384",
  "54004013",
  "53996698",
  "53999288",
  "54003726",
  "53997670",
  "53999080",
  "54003890",
  "54003650",
  "54000920",
  "54003932",
  "54000219",
  "54003171",
  "54001639",
  "54009434",
  "54012438",
  "54003080",
  "53997548",
  "53993505",
  "54003403",
  "54011695",
  "54004344",
  "54003395",
  "53998009",
  "54004039",
  "54004021",
  "54003544",
  "54010895",
  "54004633",
  "54010440",
  "53996284",
  "54003494",
  "54002843",
  "54000243",
  "54002397",
  "54008659",
  "54000250",
  "54003213",
  "53999619",
  "54007347",
  "53999635",
  "53999411",
  "54001001",
  "54002017",
  "53997928",
  "53997522",
  "54003064",
  "53998926",
  "53999122",
  "54010697",
  "54002579",
  "54000904",
  "53999353",
  "53999296",
  "54006208",
  "54001696",
  "54001142",
  "53996540",
  "54001308",
  "54008675",
  "54010051",
  "54000284",
  "54001399",
  "54001266",
  "54001548",
  "54001613",
  "54007115",
  "53997183",
  "54010705",
  "54001944",
  "53996953",
  "54003361",
  "54004054",
  "54008667",
  "54001738",
  "53999338",
  "54003098",
  "53997837",
  "54006083",
  "54000896",
  "54002058",
  "53998884",
  "54003049",
  "54004112",
  "54001779",
  "53995955",
  "54004278",
  "54003320",
  "54001555",
  "54002520",
  "53999726",
  "53999601",
  "53996714",
  "54002355",
  "53999817",
  "53999304",
  "53997597",
  "54012016",
  "54001951",
  "54005978",
  "54001985",
  "54003312",
  "54011018",
  "53996342",
  "53996334",
  "54007719",
  "54003478",
  "54002199",
  "53999015",
  "54008709",
  "53995179",
  "54010986",
  "54004096",
  "54007453",
  "54002082",
  "53996490",
  "54001704",
  "54001670",
  "54001936",
  "54006554",
  "54005390",
  "54003296",
  "53997993",
  "54001126",
  "54004435",
  "53998058",
  "54003304",
  "54004971",
];
const imeis = window.location.hostname === "titan.adaptideations.com" ? prodIMEIs : testIMEIs;

// ----singapore congestion fix part 1/3 starts----
const KSC_SINGAPORE_BATCH_1 = imeis;
const KSC_SINGAPORE_BATCH_1_length = KSC_SINGAPORE_BATCH_1.length;
const KSC_SINGAPORE_BATCH_1_Groups = 7;
const get_KSC_SINGAPORE_BATCH_1_Offset = (imei) => {
  const deviceIndex = KSC_SINGAPORE_BATCH_1.findIndex(
    (dImei) => dImei === imei,
  );
  if (deviceIndex === -1) return 0;
  const groupIndex = Math.ceil(
    (deviceIndex + 1)
      / Math.round(KSC_SINGAPORE_BATCH_1_length / KSC_SINGAPORE_BATCH_1_Groups),
  );
  const offSet = 2 * (groupIndex - 4) * 60 + (deviceIndex % 60);
  return offSet;
};
// ----singapore congestion fix part 1/3 ends----

const getDevices = (uid) => new Promise((resolve, reject) => {
  const url = uid
    ? `${APIURL}/v1.0/devices/?applicationUid=titan&uid=${uid}`
    : `${APIURL}/v1.0/devices/?applicationUid=titan&`;
  API.get(url)
    .then((response) => {
      console.log("loading devices response", response);
      if (
        response.data
          && response.data.status
          && response.data.status === "success"
      ) {
        resolve(response.data.data);
      } else {
        reject(new Error(response));
      }
    })
    .catch((err) => {
      reject(err);
    });
});

/**
 * Device pings are queried using this function
 */
const getDevicePings = (query) => new Promise((resolve, reject) => {
  const url = `${APIURL}/v1.0/devicepings/?applicationUid=titan&uid=${query.uid}&startTime=${query.startTime}&endTime=${query.endTime}`;
  API.get(url)
    .then((response) => {
      if (
        response.data
          && response.data.status
          && response.data.status === "success"
      ) {
        resolve(response.data.data);
      } else {
        reject(new Error(response));
      }
    })
    .catch((err) => {
      console.log("error caught on url query", err);
      reject(err);
    });
});

const defaultColDef = {
  sortable: true,
  filter: false,
  resizable: true,
  cellStyle: { textAlign: "left" },
};

const customURLtooltip = (params) => params.value;
function lastUpdateFormatter(params) {
  if (params.data.lastPing && params.data.lastPing.timestamp) {
    const lastUpdatedAgo = moment(
      params.data.lastPing.timestamp * 1000,
    ).fromNow();
    const lastUpdatedTime = moment(
      params.data.lastPing.timestamp * 1000,
    ).format("hh:mm A, DD-MM-YYYY");
    return `${lastUpdatedAgo} | ${lastUpdatedTime}`;
  }
  return "-";
}

function batteryFormatter(params) {
  if (params.data.lastPing && params.data.lastPing.battery) {
    return `${params.data.lastPing.battery}`;
  }
  return "-";
}
const columnDefs = [
  {
    headerName: "Device ID",
    field: "id",
    sortable: true,
    filter: true,
    sort: "asc",
    minWidth: 150,
    tooltipValueGetter: customURLtooltip,
    checkboxSelection: true,
    cellRenderer: (params) => {
      const link = document.createElement("a");
      link.href = `/debug/uids=${params.data.uid}`;
      link.target = "_blank";
      link.innerText = `${params.value} (${params.data.offset})`;
      // link.addEventListener('click', (e) => {
      //   e.preventDefault();
      //   console.log(params.data.id);
      // });
      return link;
    },
  },
  {
    headerName: "Last Updated",
    field: "lastPing.timestamp",
    sortable: true,
    minWidth: 250,
    valueFormatter: lastUpdateFormatter,
    // valueGetter: lastUpdateFormatter,
    tooltipValueGetter: lastUpdateFormatter,
  },
  {
    headerName: "Added On",
    field: "addedOn",
    sortable: true,
    minWidth: 200,
    valueFormatter: (params) => (params.data.addedOn
      ? moment(params.data.addedOn * 1000).format("DD-MM-YYYY, hh:mm A")
      : "-"),
    // valueGetter: (params) => (params.data.addedOn ? moment(params.data.addedOn * 1000).format('DD-MM-YYYY') : '-'),
    tooltipValueGetter: (params) => (params.data.addedOn
      ? moment(params.data.addedOn * 1000).format("DD-MM-YYYY, hh:mm A")
      : "-"),
  },
  {
    headerName: "Battery %",
    field: "lastPing.battery",
    sortable: true,
    minWidth: 150,
    valueFormatter: batteryFormatter,
  },
  {
    headerName: "Type",
    field: "type",
    sortable: true,
    filter: true,
    minWidth: 150,
  },
  {
    headerName: "Firmware Version",
    field: "firmwareVersion",
    sortable: true,
    filter: true,
    minWidth: 150,
  },
  {
    headerName: "UID",
    field: "uid",
    sortable: true,
    filter: true,
    minWidth: 150,
    tooltipValueGetter: customURLtooltip,
  },
  {
    headerName: "Company ID",
    field: "companyId",
    sortable: true,
    filter: true,
    minWidth: 150,
    tooltipValueGetter: customURLtooltip,
  },
  {
    headerName: "SIM UID",
    field: "SIMuid",
    sortable: true,
    // filter: true,
    minWidth: 150,
    tooltipValueGetter: customURLtooltip,
  },
  {
    headerName: "Forward URL",
    field: "forwardURL",
    sortable: true,
    // filter: true,
    minWidth: 250,
    tooltipValueGetter: customURLtooltip,
  },
];

const overlayLoadingTemplate = "<span class=\"ag-overlay-loading-center\"><img src=\"./../img/horizontalLoader.svg\" /></span>";
const overlayNoRowsTemplate = "<span>No Devices</span>";

const getChartOptions = (items) => {
  const data1 = items.map((device) => (device.devicePings || []).map((ping) => [ping.ping_time, 1]));
  const data2 = items.map((device) => (device.devicePings || []).map((ping) => [ping.kv_timestamp * 1000, 1]));
  const data3 = items.map((device) => (device.devicePings || []).map((ping) => [ping.timestamp * 1000, 1]));
  const option = {
    xAxis: {
      type: "time",
    },
    yAxis: {
      type: "value",
      minInterval: 1,
    },
    legend: {},
    dataZoom: [
      {
        type: "slider",
        bottom: 0,
        showDetail: false,
        showDataShadow: false,
      },
    ],
    tooltip: {
      show: true,
      axisPointer: {
        animation: true,
      },
      formatter: (params) => {
        const momentObj = moment(params.data[0]);
        return `${momentObj.toString()} <br /> ${momentObj.unix()}`;
      },
    },
    series: [
      {
        name: "Connect time",
        data: data1[0],
        type: "bar",
        itemStyle: { color: "#0f0" },
        barMinWidth: "3",
      },
      {
        name: "Device time",
        data: data2[0],
        type: "bar",
        itemStyle: { color: "#00f" },
        barMinWidth: "3",
        visible: false,
      },
      {
        name: "ping time",
        data: data3[0],
        type: "bar",
        itemStyle: { color: "#f00" },
        barMinWidth: "3",
        visible: false,
      },
    ],
  };
  return option;
};

const getOverviewChartOptions = (devices) => {
  const data = devices
    .map((d) => {
      const lastPing = d.lastPing || {};
      const lastPingTime = lastPing.ping_time || null;
      return [lastPingTime, 1, d.id];
    })
    .filter((d) => Date.now() - new Date(d[0]).getTime() < 24 * 60 * 60 * 1000);
  const option = {
    xAxis: {
      type: "time",
    },
    yAxis: {
      type: "value",
      minInterval: 1,
    },
    legend: {},
    dataZoom: [
      {
        type: "slider",
      },
    ],
    tooltip: {
      show: true,
      axisPointer: {
        animation: true,
      },
      formatter: (params) => {
        const momentObj = moment(params.data[0]);
        return `${
          params.data[2]
        } - ${momentObj.toString()} <br /> ${momentObj.unix()}`;
      },
    },
    series: [
      {
        name: "Connect time",
        data,
        type: "bar",
        itemStyle: { color: "#0f0" },
        barMinWidth: "3",
        barMaxWidth: "3",
      },
    ],
  };
  return option;
};

function KSC() {
  const [devicesFromServer, setDevicesFromServer] = useState([]);
  const grid = useRef();
  const echarts = useRef();
  const [selectedRows, setSelectedRows] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [doubleClickedData, setDoubleClickedData] = useState(false);
  const [selectedDates, setSelectedDates] = useState([
    moment().subtract(5, "hours").toDate(),
    moment().add(1, "hours").toDate(),
  ]);
  const [selectedQuickOption, setSelectedQuickOption] = useState(0);
  const refreshDevicesFromServer = () => {
    getDevices()
      .then((devices) => {
        setDevicesFromServer(devices);
      })
      .catch((err) => {
        console.log("Error getting devices from server", err.message || err);
      });
  };
  useEffect(() => {
    refreshDevicesFromServer();
  }, []);
  const devices = devicesFromServer
    .filter((d) => imeis.includes(d.uid))
    .map((d) => ({ ...d, offset: get_KSC_SINGAPORE_BATCH_1_Offset(d.uid) }));
  // const devices = devicesFromServer.map((d) => ({ ...d, offset: get_KSC_SINGAPORE_BATCH_1_Offset(d.uid) }));
  const unusedDevices = devices.filter(
    (d) => d.lastPing && d.lastPing.interval.transmit === 240,
  );
  const usedCount = devices.length - unusedDevices.length;
  const rowData = devices;

  const onGridReady = (params) => {
    grid.current = params.api; // <== this is how you save it
  };
  const onSelectionChanged = () => {
    if (grid && grid.current) {
      setSelectedRows(grid.current.getSelectedRows());
    } else {
      console.log("onSelectionChanged: Grid api not available");
    }
  };
  const refreshData = async () => {
    const deviceIMEIs = selectedRows.map((row) => row.uid);
    const timestamps = selectedDates.map((date) => moment(date).unix());
    const chartDataTemp = [...selectedRows];
    let index = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const imei of deviceIMEIs) {
      // eslint-disable-next-line no-await-in-loop
      const devicePings = await getDevicePings({
        uid: imei,
        startTime: timestamps[0],
        endTime: timestamps[1],
      }).catch((err) => {
        console.log("Error getting pings", err.message || err);
      });
      chartDataTemp[index].devicePings = devicePings;
      index += 1;
    }
    console.log("chartData::", chartDataTemp);
    setChartData(chartDataTemp);
  };

  useEffect(() => { refreshData(); }, [selectedRows, selectedDates]);

  const onRowDoubleClicked = (params) => {
    setDoubleClickedData(params.data);
    setDialogOpen(true);
  };

  const onGridSizeChanged = () => {
    if (grid && grid.current) {
      grid.current?.sizeColumnsToFit();
    } else {
      console.log("onGridSizeChanged: Grid api not available");
    }
  };
  let columnsOnGrid = columnDefs;
  const dataSizes = { table: 12, chart: 0 };
  if (selectedRows.length) {
    columnsOnGrid = [columnDefs[0]];
    dataSizes.table = 2;
    dataSizes.chart = 10;
  }

  const handleDateChange = (params) => {
    setSelectedDates(params);
  };

  /**
   * This function takes care of setting dates in state based on quick option selected
   * This also refreshes data accordingly
   */
  const handleQuickOptionsChange = (value) => {
    if (![1, 2, 7, 15, 30].includes(value)) return;
    const endDate = moment().add(1, "hours").toDate();
    let startDate = null;
    switch (value) {
      case 1:
        startDate = moment().startOf("day").toDate();
        break;
      default:
        startDate = moment().subtract(value, "d").startOf("day").toDate();
        break;
    }
    setSelectedQuickOption(value);
    setSelectedDates([startDate, endDate]);
  };

  return (
    <div style={{ padding: "3em 3em" }}>
      <Typography variant="h4">KSC Singapore devices</Typography>
      <Grid container spacing={3}>
        <Grid item md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" color="textSecondary" gutterBottom>
                Used
              </Typography>
              <Typography variant="h5">{usedCount}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card>
            <CardContent>
              <Typography variant="h5" color="textSecondary" gutterBottom>
                Unused
              </Typography>
              <Typography variant="h5">{devices.length - usedCount}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} />
      </Grid>
      <Grid container style={{ padding: "3em" }}>
        <Grid item md={12}>
          <Typography variant="h6" color="textSecondary" align="center">
            Last ping stats (last 24 hours)
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                refreshDevicesFromServer();
              }}
            >
              Refresh
            </Button>
          </Typography>

          <ReactEcharts
            // ref={echarts2}
            onEvents={{}}
            option={getOverviewChartOptions(devices)}
            notMerge
            lazyUpdate
            style={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: "3em" }}>
        <Grid item md={dataSizes.table}>
          <div className="ag-theme-material">
            <AgGridReact
              ref={grid}
              enableBrowserTooltips
              columnDefs={columnsOnGrid}
              defaultColDef={defaultColDef}
              rowData={rowData}
              rowSelection="single"
              onGridSizeChanged={onGridSizeChanged}
              onSelectionChanged={onSelectionChanged}
  // rowMultiSelectWithClick="true"
              suppressRowClickSelection
              onGridReady={onGridReady}
              pagination
              paginationPageSize={10}
              domLayout="autoHeight"
              enableCellTextSelection
              overlayLoadingTemplate={overlayLoadingTemplate}
              overlayNoRowsTemplate={overlayNoRowsTemplate}
  // modules={AllCommunityModules}
              rowDeselection
              onRowDoubleClicked={onRowDoubleClicked}
            />
          </div>
        </Grid>
        {!!selectedRows.length && (
        <Grid item md={dataSizes.chart}>
          <DateAndTimeRangePicker
            date={selectedDates}
            onChange={handleDateChange}
            selectedQuickOption={selectedQuickOption}
            handleQuickOptionsChange={(value) => {
              handleQuickOptionsChange(value);
            }}
          />
          <ReactEcharts
            ref={echarts}
            onEvents={{}}
            option={getChartOptions(chartData)}
            notMerge
            lazyUpdate
            style={{ width: "100%" }}
          />
        </Grid>
        )}
      </Grid>
      <Dialog
        onClose={() => {
          setDialogOpen(false);
        }}
        open={dialogOpen}
      >
        <ReactJSON src={doubleClickedData} collapsed="1" />
      </Dialog>
    </div>
  );
}

export default KSC;
