/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-filename-extension */

// TODO: useMemo for destructuring value in context.
import React, {
  useState, createContext, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getDeviceType } from '../api/getDeviceType';

const defaultValues = {};

const UserContext = createContext(defaultValues);

const UserProvider = ({ children }) => {
  const [accountUser, setAccountUser] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [devicesdata, setDevicesData] = useState(null);
  const [usersdata, setUsersData] = useState(null);
  const [consignmentsData, setConsignmentsData] = useState(null);
  const [userGroupData, setUserGroupData] = useState(null);
  const [rolesData, setRolesData] = useState(null);
  const [telemetryData, setTelemetryData] = useState(null);
  const [dateData, setDate] = useState([
    moment().subtract(1, 'month').toDate(),
    moment().toDate(),
  ]);
  const [consignmentFilterBy, setConsignmentFilterBy] = useState('startDate');
  const [selectedQuickOption, setSelectedQuickOption] = useState(30);
  const [prevCompanyId, setPrevComapnyId] = useState(null);
  const [prevApplication, setPrevApplication] = useState(null);
  const [prevConsignmentFilterBy, setPrevConsignmentFilterBy] = useState(null);
  const [prevStartDateOnly, setPrevStartDateOnly] = useState(null);
  const [prevEndDateOnly, setPrevEndDateOnly] = useState(null);
  const [telemetryDateData, setTelemetryDateData] = useState([
    moment().startOf('month').toISOString(),
    moment().toISOString(),
  ]);
  const [prevDeviceType, setPrevDeviceType] = useState(null);
  const [prevTelemetryCompanyId, setPrevTelemetryComapnyId] = useState(null);
  const [selectedTelemetryQuickOption, setSelectedTelemetryQuickOption] = useState(0);
  const [prevTelemetryStartDateOnly, setPrevTelemetryStartDateOnly] = useState(null);
  const [prevTelemetryEndDateOnly, setPrevTelemetryEndDateOnly] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [deviceTrips, setDeviceTrips] = useState({});
  const [deviceTypes, setDeviceTypes] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const deviceTypeData = await getDeviceType();
  //       setDeviceTypes(deviceTypeData);
  //     } catch (err) {
  //       console.error('error is', err);
  //     }
  //   })();
  // }, []);

  return (
    <UserContext.Provider
      value={{
        accountUser,
        setAccountUser,
        permissions,
        setPermissions,
        devicesdata,
        setDevicesData,
        usersdata,
        setUsersData,
        consignmentsData,
        setConsignmentsData,
        userGroupData,
        setUserGroupData,
        rolesData,
        setRolesData,
        telemetryData,
        setTelemetryData,
        dateData,
        setDate,
        consignmentFilterBy,
        setConsignmentFilterBy,
        selectedQuickOption,
        setSelectedQuickOption,
        prevCompanyId,
        setPrevComapnyId,
        prevApplication,
        setPrevApplication,
        prevConsignmentFilterBy,
        setPrevConsignmentFilterBy,
        prevStartDateOnly,
        setPrevStartDateOnly,
        prevEndDateOnly,
        setPrevEndDateOnly,
        telemetryDateData,
        setTelemetryDateData,
        prevDeviceType,
        setPrevDeviceType,
        prevTelemetryCompanyId,
        setPrevTelemetryComapnyId,
        selectedTelemetryQuickOption,
        setSelectedTelemetryQuickOption,
        prevTelemetryStartDateOnly,
        setPrevTelemetryStartDateOnly,
        prevTelemetryEndDateOnly,
        setPrevTelemetryEndDateOnly,
        rowData,
        setRowData,
        deviceTrips,
        setDeviceTrips,
        setDeviceTypes,
        deviceTypes,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => useContext(UserContext);

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserProvider, useUserContext };
