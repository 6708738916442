import React from 'react';
import './DateAndTimeRangePicker.css';
import Flatpickr from 'react-flatpickr';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles({
  Flatpickr: {
    border: 'none',
    borderRadius: '30px',
    paddingLeft: '5px',
  },
  selectionBox: {
    background: '#eee',
    // width: '20%',
    // height: "35px",
    borderRadius: '30px',
    // outline: "none",
    // "&:focus": {
    //   border: "2px solid #00adef",
    // },
    // margin: "5px 10px",
    // display: "flex",
    // flexWrap: "wrap",
  },
  timeLabel: {
    fontSize: '12px',
    fontFamily: 'Montserrat',
  },
});

function DateAndTimeRangePicker({
  date,
  onChange,
  handleQuickOptionsChange,
  selectedQuickOption,
  quickOptions = {
    CUSTOM: 0,
    TODAY: 1,
    'LAST 2 DAYS': 2,
    'LAST 3 DAYS': 3,
    'LAST WEEK': 7,
    'LAST 15 DAYS': 15,
    'LAST MONTH': 30,
  },
  options = [],
  width = '250px',
}) {
  const classes = useStyles();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          border: '1px solid lightgrey',
          borderRadius: '30px',
        }}
      >
        <FormControl className={classes.selectionBox} size="small">
          <Select
            sx={{
              fontSize: '12px',
              fontFamily: 'Montserrat',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
              '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
              {
                border: 0,
              },
              '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: 0,
              },
            }}
            value={selectedQuickOption}
            onChange={(e) => { handleQuickOptionsChange?.(Number.isNaN(Number('e.target.value')) ? e.target.value : Number(e.target.value)); }}
          >
            {Object.entries(quickOptions).map(([k, v]) => (
              <MenuItem key={k} value={v} className={classes.timeLabel} disabled={v === 0}>
                {k}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Flatpickr
          data-enable-time
          value={date}
          onChange={onChange}
          className={classes.Flatpickr}
          style={{ width }}
          options={{
            mode: 'range',
            time_24hr: true,
            // altFormat: "j M y, H:i",
            // altInput: true,
            defaultHour: 0,
            locale: {
              firstDayOfWeek: 1,
            },
            ...options,
          }}
        />
      </div>
    </div>
  );
}

DateAndTimeRangePicker.propTypes = {
  date: PropTypes.array,
  onChange: PropTypes.func,
  handleQuickOptionsChange: PropTypes.func,
  selectedQuickOption: PropTypes.number,
  // minDate: PropTypes.string,
  // maxDate: PropTypes.string,
};

export default DateAndTimeRangePicker;
