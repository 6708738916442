import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Zoom,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export default function SessionExpired({ keycloak, setKeycloak, verify }) {
  const [open, setOpen] = useState(false);
  const handleOk = () => {
    setOpen(false);
    // if (window.top === window.self) setKeycloak({ authenticated: false });
    // else
    verify('login-required');
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (keycloak?.tokenParsed?.exp) {
      console.log(`Sign-In expires on ${moment.unix(keycloak.tokenParsed.exp)}`);
      setTimeout(() => {
        if (keycloak.isTokenExpired()) setOpen(true);
      }, keycloak.tokenParsed.exp * 1000 - Date.now());
    }
  }, [keycloak]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Zoom}
      // onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      scroll="body"
    >
      <DialogTitle id="form-dialog-title">
        Session Expired
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your session has expired. Please login again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="error">Cancel</Button>
        <Button onClick={handleOk}>Login</Button>
      </DialogActions>
    </Dialog>
  );
}
