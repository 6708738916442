import { Button, Typography } from '@mui/material';
import cogoToast from 'cogo-toast';
import React from 'react';

let windowObjectReference = null;

const AUTH_SERVER_API = process.env.REACT_APP_APIURL.startsWith('https://titanapi.adaptideations.com')
  ? 'https://auth.adaptideations.com/auth/realms/Titan'
  : 'https://authdev.adaptideations.com/auth/realms/PlatformTest';

const popupCenter = (url, title, w, h) => {
  const dualScreenLeft = 0; // window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = 0; // window.screenTop !== undefined ? window.screenTop : window.screenY;

  // eslint-disable-next-line no-nested-ternary
  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
  // eslint-disable-next-line no-nested-ternary
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

  const systemZoomW = 1; // width / window.screen.availWidth;
  const systemZoomH = height / window.screen.availHeight;
  const left = (width - w) / 2 / systemZoomW + dualScreenLeft;
  const top = (height - h) / 2 / systemZoomH + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoomW}, 
    height=${h / systemZoomH}, 
    top=${top}, 
    left=${left}
    `,
  );

  if (window.focus) newWindow.focus();

  return newWindow;
};

const buttonStyle = {
  color: '#008081',
  borderRadius: '50px',
  margin: '0% 1%',
  borderColor: '#008081',
  fontWeight: 'bold',
  '&:hover': {
    background: '#008081',
    color: 'white',
  },
};

const Home = ({ login }) => {
  const eventListenerFunc = (event) => {
    if (event.origin === window.origin && event.data) {
      // console.log(event.data);
      window.location.reload();
      window.removeEventListener('message', eventListenerFunc);
    }
  };

  // const login = () => {
  //   window.addEventListener('message', eventListenerFunc);
  //   const url = `${AUTH_SERVER_API}/protocol/openid-connect/auth?client_id=frontend-app&redirect_uri=${window.origin}/silent-post-login.html&response_type=code&scope=openid`;
  //   if (windowObjectReference === null || windowObjectReference.closed) {
  //     windowObjectReference = popupCenter(url, 'signInWindow', '500', '700');
  //     if (!windowObjectReference) cogoToast.error('Please enable pop-ups', { position: 'top-right' });
  //   } else {
  //     windowObjectReference.focus();
  //   }
  // };

  return (
    <div style={{
      background: '#eeeeee88',
      minHeight: '100vh',
      minWidth: '100vw',
    }}
    >
      <div style={{
        backgroundImage: `url(${window.origin}/img/homebgr.png)`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        minWidth: '100vw',
      }}
      >
        <div style={{
          padding: '0% 8%', display: 'flex', alignItems: 'center', height: 'calc(20vh)',
        }}
        >
          <img
            src="/img/loggers-logo.png"
            alt="company logo"
            style={{
              maxHeight: '100px',
              maxWidth: '100px',
            }}
          />
          <span style={{ flexGrow: '1' }} />
          <Button
            variant="outlined"
            onClick={() => { window.location.href = 'https://titan.adaptideations.com'; }}
            sx={buttonStyle}
          >
            Prod
          </Button>
          <Button
            variant="outlined"
            onClick={() => { window.location.href = 'https://titantest.adaptideations.com'; }}
            sx={buttonStyle}
          >
            Test
          </Button>
          <Button
            variant="outlined"
            onClick={() => { window.location.href = 'https://titandev.adaptideations.com'; }}
            sx={buttonStyle}
          >
            Dev
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
            padding: '1% 8%',
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: 'bold',
              color: '#008081',
              fontSize: 'calc(max(8vw, 8vh))',
              textOverflow: 'hidden',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              letterSpacing: '1.5rem',
            }}
          >
            TITAN
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#83bebf' }}>
            TRACK AND MONITOR
          </Typography>
          <Button
            sx={{
              background: 'goldenrod',
              marginTop: '2rem',
              textTransform: 'none',
              fontSize: 16,
              fontWeight: 'bold',
              minWidth: '10rem',
              '&:hover': {
                background: 'white',
                color: 'goldenrod',
              },
            }}
            variant="contained"
            onClick={() => { login('login-required'); }}
          >
            Sign-In
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
